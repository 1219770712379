<!-- Código html -->
<template>
    
    <!-- Contenedor principal -->
    <div id="contenedor_principal">


            <div class="titulo">
                Web Service Pogen Data
            </div>

            <div class="parrafo">
                El presente documento detalla el consumo del web service de 
                Pogen, basado en soap, para la integración de los sistemas 
                de los usuarios a través de la obtención de la información
                de entradas de sus plazas/tiendas
            </div>

            <div class="parrafo">
                Los datos para consumir el web service son:
                <ol>
                    <li>Host:https://www.pogendata.com/webservice</li>
                    <li>WSDL:https://www.pogendata.com/webservice?wsdl</li>
                    <li>Usuario: El correo con el cual inicia sesión en	nuestro
                        portal www.pogendata.com</li>
                    <li>Contraseña: La contraseña con la cual inicia sesión en
                        nuestro portal.</li>
                    <li>Codificación(encoding): El encoding de las solicitudes 
                        al web service deben ser en UTF-8</li>
                </ol>
            </div>

            <div class="titulo1 mt2em">
                Autenticación
            </div>

            <div class="parrafo">
                El método utilizado para la autenticación es "basic" debiendo
                proveer las credenciales con las cuales inicia sesión en 
                nuestro portal www.pogendata.com en los headers de la solicitud
                http.
            </div>

            <div class="parrafo">
                En caso de una autenticación fallida, el servidor retornara un
                SOAP-ENV:Faul mensaje describiendo el error
            </div>

            <div class="titulo1">
                Entradas por día
            </div>

            <div class="parrafo">
                Método utilizado para obtener la información del conteo de 
                entradas de sus plazas/tiendas. La información se encuentra
                agrupada por plazas/tienda y fecha.
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parámetros:
                </div>                
                <ul>
                    <li><strong>email</strong>: Correo usuario</li>
                    <li><strong>password</strong>: Contraseña de usuario</li>
                    <li><strong>from</strong>: Fecha Inicial (YYYY-MM-DD)</li>
                    <li><strong>to</strong>: Fecha Final (YYYY-MM-DD)</li>
                </ul>
                <div class="categoria">
                    Respuesta:
                </div>                
                <ul>
                    <li>
                        Arreglo de objetos con las propiedades plaza_id, fecha 
                        y entradas.
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">PHP</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">
/* Función para consultar las entradas en un rango de fechas específico */
function consultarEntradas($usuario,$contrasenia,$inicio,$fin){

    
    /* Inicio del bloque try catch */
    try{
        
        /* Crear cliente soap */
        $clienteSoap = new SoapClient("https://www.pogendata.com/webservice?wsdl",
            array(
                "trace"=>1,
                "login"=>$usuario,
                "password"=>$contrasenia
            )
        );

        /* Crear un objeto tipo arreglo con los parametros a enviar */
        $parametros = array(
            "from" => $inicio,
            "to" => $fin
        );

        /* Hacer la llamada al método query y asignar la respuesta */
        $respuesta = $clienteSoap->__soapcall('query',$parametros);

        /* Retornar la respuesta */
        return $respuesta;

    }catch(Exception $e){

        /* En caso de error imprimirlo */
        echo "Falló función: " . $e->getMessage();

    }

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# Importar librerías adicionales
from zeep import Client
from requests.auth import HTTPBasicAuth
from requests import Session
from zeep.transports import Transport

# Obtener las entradas
def obtenerEntradas(usuario,contrasenia,inicio,fin):
    
    # Crear una sesion para manejar las credenciales
    sesion = Session()

    # Asignar el usuario y la contraseña a la sesión
    sesion.auth = HTTPBasicAuth(usuario,contrasenia)

    # Crear cliente y agregar credenciales
    cliente = Client('https://www.pogendata.com/webservice?wsdl',transport=Transport(session=sesion))

    # Usar el cliente con el respectivo servicio y método query para realizar la consulta pasando parámetros de inicio y fin
    respuesta = cliente.service.query(inicio,fin)

    # Retornar respuesta
    return respuesta
                        </code>
                    </pre>                    
                </div>
            </div>

            <div class="titulo1">
                Entradas por hora
            </div>

            <div class="parrafo">
                Método utilizado para obtener la información del conteo de 
                entradas de sus plazas/tiendas por hora. La información se
                encuentra agrupada por plazas/tienda y fecha.
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parámetros:
                </div>                
                <ul>
                    <li><strong>email</strong>: Correo usuario</li>
                    <li><strong>password</strong>: Contraseña de usuario</li>
                    <li><strong>from</strong>: Fecha Inicial (YYYY-MM-DD HH:mm:ss)</li>
                    <li><strong>to</strong>: Fecha Final (YYYY-MM-DD HH:mm:ss)</li>
                </ul>
                <div class="categoria">
                    Respuesta:
                </div>                
                <ul>
                    <li>
                        Arreglo de objetos con las propiedades plaza_id, fecha 
                        y entradas.
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">PHP</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">
/* Función para consultar entradas por horas con rango específico de 
fechas, los parámetros son usuario, contraseña, inicio(YYYY-MM-DD HH:MM:SS)
fin (YYYY-MM-DD HH:MM:SS) */
function consultarHoras($usuario,$contrasenia,$inicio,$fin){

    /* Se abre bloque try catch */
    try{

        /* Crear objeto cliente soap con url, usuario y contraseña */
        $clienteSoap = new SoapClient("https://www.pogendata.com/webservice?wsdl",
            array(
                'trace'=>1,
                'login'=>$usuario,
                'password'=>$contrasenia
            )
        );

        $parametros = array(
            'from'=>$inicio,
            'to'=>$fin
        );

        $respuesta = $clienteSoap->__soapcall('hours',$parametros);

        return $respuesta;

    }catch(Exception $e){

        /* En caso de excepción se imprime el error */
        echo "Falló en la función consultarHoras: " . $e->getMessage();

    }

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# Librerías adicionales
from zeep import Client
from requests.auth import HTTPBasicAuth
from requests import Session
from zeep.transports import Transport

# Función para obtener las entradas por horas
def EntradasHoras(usuario,contrasenia,inicio,fin):

    # Crear la sesión para manejar las credenciales
    sesion = Session()

    # Agregar credenciales a la sesión
    sesion.auth = HTTPBasicAuth(usuario,contrasenia)

    # Crear el cliente con la url y las credenciales
    cliente = Client("https://www.pogendata.com/webservice?wsdl",transport=Transport(session=sesion))

    # Realizar la petición y asignar la respuesta
    respuesta = cliente.service.hours(inicio,fin)

    #retornar respuesta
    return respuesta
                        </code>
                    </pre>                    
                </div>
            </div>

            <div class="titulo1">
                Entradas por acceso por hora
            </div>

            <div class="parrafo">
                Método utilizado para obtener la información del conteo de 
                entradas de sus plazas/tiendas por acceso por hora. La 
                información se  encuentra agrupada por plazas/tienda y fecha.
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parámetros:
                </div>                
                <ul>
                    <li><strong>email</strong>: Correo usuario</li>
                    <li><strong>password</strong>: Contraseña de usuario</li>
                    <li><strong>from</strong>: Fecha Inicial (YYYY-MM-DD HH:mm:ss)</li>
                    <li><strong>to</strong>: Fecha Final (YYYY-MM-DD HH:mm:ss)</li>
                </ul>
                <div class="categoria">
                    Respuesta:
                </div>                
                <ul>
                    <li>
                        Arreglo de objetos con las propiedades plaza_id, fecha 
                        y entradas.
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">PHP</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">
/* Función para consultar entradas por acceso por horas con rango 
específico de fechas, los parámetros son usuario, contraseña, 
inicio(YYYY-MM-DD HH:MM:SS), inicio(YYYY-MM-DD HH:MM:SS) */    
function consultarAccesoPorHoras($usuario,$contrasenia,$inicio,$fin){

    /* Iniciar bloque try catch */
    try{

        $clienteSoap = new SoapClient("https://www.pogendata.com/webservice?wsdl",
            array(
                'trace' => 1,
                'login' => $usuario,
                'password' => $contrasenia
            )
        );

        $parametros = array(
            'from' => $inicio,
            'to' => $fin
        );

        $respuesta = $clienteSoap->__soapcall('hoursByAccess',$parametros);

        return $respuesta;

    }catch(Exception $e){

        /* Imprimir error */
        echo "Falló la función consultarAccesoPorHoras: ".$e->getMessage();

    }

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# Importar librerías adicionales
from zeep import Client
from requests import Session
from requests.auth import HTTPBasicAuth
from zeep.transports import Transport

# Metodo para obtener las entradas por horas por acceso
def EntradasHorasAcceso(usuario, contrasenia, inicio, fin):

    # Crear objeto que maneje las credenciales de acceso
    sesion = Session()

    # Agregar credenciales al objeto
    sesion.auth = HTTPBasicAuth(usuario,contrasenia)

    # Crear el cliente con url y credenciales
    cliente = Client("https://www.pogendata.com/webservice?wsdl", transport=Transport(session=sesion))

    # Realizar consulta y asignarla respuesta a una variable
    respuesta = cliente.service.hoursByAccess(inicio,fin)

    # Retornar respuesta
    return respuesta
                        </code>
                    </pre>                    
                </div>
            </div>

            <div class="titulo1">
                Listado de establecimientos
            </div>

            <div class="parrafo">
                Método utilizado para obtener la lista de establecimientos 
                asociados al usuario proporcionado por medio de las 
                credenciales.
            </div>

            <div class="detalles">

                <div class="categoria">
                    Respuesta:
                </div>

                <ul>
                    <li>
                        Arreglo de objetos con las propiedades id y nombre.
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">PHP</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">
/* Función para consultar establecimientos, parámetros necesarios usuario y
password */
function consultarEstablecimientos($usuario,$contrasenia){
    
    /* Se abre el bloque try */
    try{
        
        /* Crear objeto cliente soap */
        $clienteSoap = new SoapClient("https://www.pogendata.com/webservice?wsdl",
            array(
                'trace'=>1,
                'login'=>$usuario,
                'password'=>$contrasenia
            )
        );
        
        /* Llamar al método establecimientos y asginar respuesta a su variable */
        $respuesta = $clienteSoap->establecimientos();

        /* El objeto respuesta contiene el arreglo con la información de 
        los diferentes establecimientos */
        return $respuesta;

    }catch(Exception $e){

        /* Imprimir exception */
        echo "Falló función: " . $e->getMessage();

    }

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# Importar librerias adicionales
from zeep import Client 
from zeep.transports import Transport
from requests.auth import HTTPBasicAuth
from requests import Session

# Método para obtener los establecimientos asociados al usuario
def Establecimientos(usuario, contrasenia):

    # Crear objeto para manejar las credenciales
    sesion = Session()

    # Agregar credenciales al objeto
    sesion.auth = HTTPBasicAuth(usuario, contrasenia)

    # Crear cliente y pasarle las credenciales
    cliente = Client("https://www.pogendata.com/webservice?wsdl", transport=Transport(session=sesion))

    # Realizar consulta y asignar resultado a una variable
    respuesta = cliente.service.establecimientos()

    # Retornar respuesta
    return respuesta
                        </code>
                    </pre>                    
                </div>
            </div>


        <footer>
            ©2021 Pogen, sistemas@pogen.com
        </footer>                     

    </div>

</template>
<!-- Código vue -->
<script>

    export default{

        data(){
            return{
                pa:0
            }
        },

        methods:{            
            animarPaneles(indice){
                let clases = ['c_php','c_py'];
                this.pa = indice;
                let contador = 0;
                clases.forEach(cU=>{
                    let pa = document.getElementsByClassName(cU);
                    for(let c = 0; c < pa.length; c++){
                        pa[c].style.height = contador === indice ? '50vh' : '0vh';
                    }
                    contador++;
                })
            }
        }

    }

</script>


<!-- Esilo propio -->
<style scoped>
    .contieneTabs{
        display: flex;
    }
    .tab{
        width:10em;
        text-align: center;
        background-color: #ccc;
        padding:.3em 0em;
        border: solid 1px #888;
        cursor: pointer;
    }
    .pizarra{
        height: 50vh;
        margin-bottom: 3em;
    }
    .c_js, .c_php, .c_net, .c_py{
        top: 0em;
        left: 0em;
        height: 0em;
        width: 100%;
        overflow-y: scroll;
        color: white;
        transition:height .3s linear;
    }
    .c_php{
        height: 50vh;
    }
    .seleccionado{
        background-color: #555;
        color: white;
    }
    .titulo1 {
    margin-top: 1em;
    font-size: 1.2em;
    color: #5a5a5a;
    padding-left: 1em;
    font-weight: 500;
    }
    .parrafo {
    text-align: justify;
    text-indent: 1em;
    padding: 0.7em;
    font-size: 0.9em;
    color: #5a5a5a;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    }
    .detalles {
    margin-top: 1.5em;
    padding-left: 1.5em;
    color: #5a5a5a;
    font-size: 0.9em;
    }
    .categoria {
    font-size: 1em;
    font-weight: 400;
    }

    .mt2em {
    margin-top: 2em;
    }
    footer {
    margin-top: 5em;
    text-align: center;
    background-color: #363636;
    border-radius: 0 0 0.5em 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: white;
    font-weight: 500;
    font-size: 0.8em;
    }                    
</style>